import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";

const PayPalButton = ({ price, customData }) => {
  const clientID =
    "AZ-xyBWSEZR8GSAGS9gYfo16LdympRHwObJBrh4VefKixIfQpbXb87EDY1V-yNTsnrSIR9KOl2BwgPeD";

  const handleApprove = (orderID) => {
    axios
      .post(
        "/quick",
        { data: customData },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Server response:", response.data);
      })
      .catch((error) => {
        console.error("Error sending data to server:", error);
      });
  };

  return (
    <PayPalScriptProvider options={{ "client-id": clientID }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price, // Переданная цена
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            handleApprove();
            alert("Order starts");
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
